/**** FAKE TABLE ****/
.card{
  overflow:unset !important;
}

.flex-table{
  display: flex;
}

.flex-row{
  display:flex;
}

.flex-column{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin:0;
  padding:0;
}

.flex-cell{
  display:flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height:30px;
  max-height:30px;
  overflow:hidden;
  margin:0;
  border:1px solid black;
  background:#ffffff;
}

.flex-row .flex-cell{
  display: flex;
  float:left;
  flex-basis: 0;
  flex: 2;
}

.flex-row.active .flex-cell{
  background:#d0edf8;
}

.flex-cell.th{
  font-weight:bold;
}

.clearfix{
  clear: both;
}

#mfi-follow-content{
  padding:15px;
  position:sticky;
  top:163px;
}

#follow-selected-mfi{
  background:#d0edf8;
}
