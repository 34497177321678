// Ce fichier doit contenir l'ensemble des variables SASS
// surchargeant les valeurs par défaut de Bulma

$navbar-background-color: #1F356A;
$navbar-burger-color: white;
$navbar-dropdown-background-color: $navbar-burger-color;
$navbar-background-color-active: adjust-color($color: $navbar-background-color, $lightness: 10%);

.field.is-horizontal{
  margin-bottom: .75rem;
}

.is-fullwidth {
  width: 100%;
}