/**** DRAWER ****/
.drawer{
    position:fixed;
    height:100vh;
    top:0;
    z-index:8000;
    background:#fff;
    transition: all .6s;
    padding-top:80px;
    overflow:auto;
    max-width: 100%;
}

.drawer.from-left{
    left:-100%;
    border-right:1px solid #F7F7F7;
}

.drawer.from-right{
    right:-100%;
    border-left:1px solid #F7F7F7;
}

.drawer.from-right.toggle{
    right:0;
    border-left:1px solid #F7F7F7;
}

.drawer.from-left.toggle{
    left:0;
    border-right:1px solid #F7F7F7;
}
