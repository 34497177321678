/**** CROSSMATCH ****/
#crossmatch-modal .modal-card{
    width:90%;
}

.crossmatch-column{
  width:12.5%;
}

#donor-typing{
  z-index:2999;
  background:#ffffff;
  border-bottom: 1px solid #ededed;
  padding-bottom:10px;
}

#mfi-container{
  padding-top:15px;
}

#toggle-full-crossmatch{
  position:absolute;
  top:15px;
  right:15px;
  cursor:pointer;
}
