$bulma-main-h: hue($navbar-burger-color);
$bulma-main-s: saturation($navbar-burger-color);
$bulma-main-l: lightness($navbar-burger-color);

$bulma-text-h: hue($navbar-burger-color);
$bulma-text-s: saturation($navbar-burger-color);
$bulma-text-l: lightness($navbar-burger-color);

$bulma-burger-h: hue($navbar-burger-color);
$bulma-burger-s: saturation($navbar-burger-color);
$bulma-burger-l: lightness($navbar-burger-color);


.navbar {
    color: white;

    --bulma-link: white;

    --bulma-main-h: #{$bulma-main-h};
    --bulma-main-s:  #{$bulma-main-s};
    --bulma-main-l:  #{$bulma-main-l};

    --bulma-text-h:  #{$bulma-text-h};
    --bulma-text-s:  #{$bulma-text-s};
    --bulma-text-l:  #{$bulma-text-l};

    --bulma-burger-h:  #{$bulma-burger-h};
    --bulma-burger-s:  #{$bulma-burger-s};
    --bulma-burger-l:  #{$bulma-burger-l};
}

.navbar .navbar-item.is-hoverable:hover > .navbar-link {
    color: #{$navbar-burger-color};
    background-color: #{$navbar-background-color-active};
    transition: none;
}

.navbar .drawer-toggle:hover {
    background-color: #{$navbar-background-color-active};
}