.app-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 160px;
  background-color: white;
  background-image: url('../resources/logo-efs.svg');
  background-position: center center;
  background-repeat: no-repeat;
  animation: animateLogo 1.2s infinite;
}

@keyframes animateLogo {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}
