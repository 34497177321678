/**** COMMON ****/
body {
  height: auto;
}

h1, h2, h3 {
  font-family: 'Bitter', serif;
}

.tooltip {
  position: absolute;
  padding: 0.35em;
  background: white;
  border: solid 1px black;
  border-radius: 5px;
}

.header-title{
  font-size:3em;
  color:#C30D20;
  line-height: 160px;
}

.card.dark{
  background:#1F356A;
  color:#ffffff;
}

.card.dark h2, .card.dark p, .card.dark a{
  color:#ffffff;
}

.modal{
  z-index:10000;
}

.modal-background{
  z-index:10000;
}

.modal-card{
  z-index:10001;
}

.overflow-hidden {
  overflow-x: hidden;
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-color:hover {
  background-color: #f5f5f5;
}

.is-flexgrow1 {
    flex-grow: 1;
}

.is-alignitemscenter {
    align-items: center;
}

.is-widthfull {
    width:100%;
}

.menu-list .navbar-item {
    display:flex;
}

.menu-list .navbar-item i {
    width:30px;
    text-align: center;
}

.is-fullheight {
  height: 100%;
}

.is-nowrap {
  white-space: nowrap;
}