#chart-area{
  position: relative;
  z-index: -1;
  line-height: 1;
  border-left: 1px solid #000;
}

#chart-actions{
  position: absolute;
  right: 0;
  top: 50px;
}


