/**** MAIN CONTENT ****/
#main-content{
    z-index:1000;
}

.bordered-right {
  border-right:1px solid #000000 !important;
}

tr.is-warning {
  background:#FFF6DE;
}
