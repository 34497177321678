.notification-bar {
  z-index: 8000;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: fixed;
  right: -50px;
  top: 60px;
}

:root {
  --warning-notification: #f2a600;
  --success-notification: #12c99b;
  --error-notification: #e41749;
}

.notification-icon-wrapper > span > img, .close > svg  {
  margin-top: 0.4rem;
}

.notification-box-shadow {
  box-shadow: 2px 3px 7px 2px #0000004f;
}

.alert {
  z-index: 6000;
  min-height: 67px;
  width: 560px;
  max-width: 90%;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 0.3rem 0;
  font-weight: 500;
  color: #fff;
}

.alert-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert-warning {
  background: var(--warning-notification);
}

.alert-success {
  background: var(--success-notification);
}

.alert-error {
  background: var(--error-notification);
}

.alert .notification-icon-wrapper {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}

.alert .notification-icon-wrapper span {
  font-size: 21px;
}

.alert p {
  color: #fff;
  margin-left: 10px;
}

.alert p a,
.alert p a:visited,
.alert p a:active {
  color: #fff;
}

.alert .open {
  margin-left: auto;
  margin-right: 5px;
}

.alert .close, .alert .open , .alert .notification-show-detail {
  color: #fff;
  transition: transform 0.5s;
  font-size: 18px;
  cursor: pointer;
}

.alert .close:hover, .alert .open:hover {
  transform: scale(1.3);
}
